svg.tooth {
    cursor: pointer;
    fill: white;
    height: 60px;
    width: 60px;
    margin-right: 10px;
    margin-bottom: 10px;
}

svg.tooth .to-do {
    fill: red;
}

svg.tooth .done {
    fill: blue;
}

svg.tooth .active {
    fill: gray;
}

svg.tooth polygon {
    stroke: black;
    stroke-width: 0.5;
}

#polygon:hover {
    fill: lightgray;
}

rect:hover {
    fill: lightgray;
}

.tooth-wrapper {
    margin: 0 2px;
    position: relative;
}

.flex-container-justify {
    display: flex;
    justify-content: space-around;
}

.flex-container-right {
    display: flex;
    justify-content: flex-end;
}

.flex-container-left {
    display: flex;
    justify-content: flex-start;
}

.tooth {
    font-size: 6pt;
    font-weight:normal;
}
.tooth-polygon {
  fill: none;
  cursor: pointer;
  pointer-events: all;
}



.tooth-polygon:hover {
  fill: grey;
  pointer-events: 'all'
}
.tooth-polygon-selected {
  fill:#3b72ff;
  cursor: pointer;
  pointer-events: all;
}
.tooth-polygon-selected-red {
  fill: #bc2c2c;
}

.tooth-polygon-sin-hover {
  fill: none;
}

.tooth-polygon-selected-sin-hover {
  fill:#3b72ff;
}
.tooth-polygon-selected-red-sin-hover {
  fill:#bc2c2c
;
}