body {
    background: #e9fffd;
}
.container{
    margin: 80px 50px 0px 50px;
}
.container .title{
    margin-bottom: 15px;
}
.container .inputWidth{
    width: 40%;
}
.container .errorMessage{
    color: red;
    margin-top: 10px;
}
.textAlign{
    text-align: center;
}
.formContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.formContainer .linkMargin {
    margin: 20px 0px 30px 0px;
}

@media (max-width: 600px) {
    .container .title {
        font-size: 22px;
    }
    .container .inputWidth{
        width: 90%;
    }
    .container .errorMessage{
        font-size: 14px;
    }
}