.Odontogram {
    text-align: center;
}

.teethContainerDesktop{
    display: flex;
    justify-content: space-around;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.teeth{
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}